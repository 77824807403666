@media all and (min-width: 1200px) {
	:root {
		--vp-width: 1200px;
	}
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	height: 100vh;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	width: 100%;
	height: 100%;
	max-width: 1400px;
	margin: 0 auto;
}

/* Overrides */

@media only screen and (max-width: 1199px) and (min-width: 768px) {
	.ui.container {
		width: auto !important;
	}
}
