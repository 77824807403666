.m-modal {
	position: fixed;
	width: 80%;
	height: 500px;
	left: 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
}
