.a-image {
	&:not(.a-image--ad) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	& > img {
		position: relative;
		width: auto;
		height: auto;
		max-height: 500px;
		max-width: 100%;
		object-fit: cover;
	}

	&__header {
		width: 50%;
		margin: 2rem auto 0;
	}

	&__meta {
		font-size: 1.4rem;
		margin-right: auto;
	}

	&--in-between {
		position: relative;
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	&--skyscraper {
		background: grey;

		& > img {
			height: 100%;
		}
	}

	&--leaderboard {
		height: 100%;
	}

	&--pop-up {
		margin: 0;
		width: 100%;
		height: 100%;
	}
}
