p:last-child {
	margin-bottom: 1.5rem !important;
}

.a-text {
	display: flex;

	&__text {
		display: block;
		font-size: 1.57rem;
		line-height: 1.5;
		font-weight: 400;
		flex: 2;
		margin-bottom: 1.5rem;

		&::selection {
			background-color: lightblue;
		}

		&--arrow-right {
			&:after {
				content: '>';
				margin: 0 1rem;
			}
		}

		&--wrap-around {
			float: right;
		}
	}

	&--watermark {
		position: absolute;
		top: 10px;
		right: 10px;

		& > p {
			font-size: 2rem;
			margin: 0;
			font-weight: 600;
			color: white;
			-webkit-text-stroke: 1px black;
		}
	}
}
