.m-ad {
	overflow: hidden;
	position: relative;

	&:not(.m-ad--skyscraper) {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	> .ui.leaderboard {
		height: 100% !important;
	}

	&--leaderboard,
	&--large-rectangle,
	&--medium-rectangle {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--vertical-rectangle {
		flex: 1;
		height: 100%;
	}
}
